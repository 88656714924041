import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import {  Grid } from '@material-ui/core';
import ButtonComponent from '@components/Shared/Buttons/Button';
import TableCustomKeysNoPagination from '@src/Pages/Private/Cliente/Admin/CorrespondenciaDevolvida/TableCustomKeysNoPagination';
import { TitlePage } from '@src/@styles/styles.global';

import InvisibleContent from '@src/components/Shared/InvisibleContent';

import Intencao from '@src/Pages/Private/shared/SolicitacaoAtendimento/ListAtendidos/Intencao';
import IntencaoMissa from '@src/Pages/Private/shared/SolicitacaoAtendimento/ListAtendidos/IntencaoMissa';



const SolicitacaoAtendimento: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState('');

  const [openIntencao, setOpenIntencao] = useState(false);
  const [openIntencaoMissa, setOpenIntencaoMissa] = useState(false);


  useEffect(() => {
    window.document.title = 'CRM - Solicitações atendimento';
  }, []);

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <TitlePage style={{ margin: 5 }}>Solicitações atendimento</TitlePage>
      </Grid>

      <Grid
        container
        alignItems="center"
        item
        md={12}
        xs={12}
        style={{ margin: 5 }}
      >

        <Grid item lg={2} md={2} xs={4} style={{ margin: 5 }}>
          <ButtonComponent
            //onClick={() => setOpenModalAddCorrespondencia(true)}
            fullWidth
            style={{ height: 46, borderRadius: 5 }}
            color={status === 'Intencao' ? 'secondary' : 'default'}
            onClick={() => (setStatus('Intencao'), setOpenIntencao(true), setOpenIntencaoMissa(false))}
          >
            Intenção
          </ButtonComponent>
        </Grid>

        <Grid item lg={2} md={2} xs={4} style={{ margin: 5 }}>
          <ButtonComponent
            fullWidth
            style={{ height: 46, borderRadius: 5}}
            color={status === 'Intencao missa' ? 'secondary' : 'default'}
            onClick={() => (setStatus('Intencao missa'), setOpenIntencaoMissa(true), setOpenIntencao(false))}
          >
            Intenção de missa
          </ButtonComponent>
        </Grid>


        <InvisibleContent visible={openIntencao === true}>


          <Intencao />
        </InvisibleContent>

        <InvisibleContent visible={openIntencaoMissa === true}>
        <IntencaoMissa />
        </InvisibleContent>

      </Grid>

    </Grid>
  );
};

export default SolicitacaoAtendimento;
