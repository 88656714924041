import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { Checkbox, Grid, IconButton } from '@material-ui/core';
import { AddBox, ClearRounded, AddOutlined} from '@material-ui/icons';
import ButtonComponent from '@components/Shared/Buttons/Button';

import { TitlePage } from '@src/@styles/styles.global';
import { IPerfil } from '@interfaces/UserComplete';
import useDebounce from '@hooks/useDebounce';
import { MaskCPFeCNPJ } from '@utils/Masks';
import { FormatDate } from '@utils/formats';
import { Form, Select as SelectAntd,  Input, Spin, Radio } from 'antd';
import { weeksToDays, subDays, addDays } from 'date-fns';

import UFs from '@components/Shared/BuscaEstados/UF2.json';
import InputCidades from '@components/Shared/EstadoCidadeInputs/cidades';
import InvisibleContent from '@components/Shared/InvisibleContent';

import Services, {
  IPerfils,
  IGetAllTipoIntencao,
  IRequestCreateIntencaoMissa,
  ICidade,
  IHorarioDiaSemana,
} from './services';

const optionsFilter = ['ID', 'CPF/CNPJ'];
let diaSemanaSelected: any;
let diaSemanaSelecionada: any;

const SolicitacaoIntencao: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [formRef] = Form.useForm();
  const [loadingCreateIntencao, setLoadingCreateIntencao] = useState(false);

  const [viewConfirmation, setViewConfirmation] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(
    optionsFilter[optionsFilter.length - 1],
  );
  const [valueInput, setValueInput] = useState('');
  const [enableSearchForm, setEnableSearchForm] = useState(false);
  const [respostaErroBuscaPerfil, setRespostaErroBuscaPerfil] = useState('');
  const [loadingTipoPerfil, setLoadingTipoPerfil] = useState(false);
  const [statePessoa, setStatePessoa] = useState({
    Nome: '',
    id: '',
  });

  const [perfis, setPerfis] = useState<IPerfil[]>([]);
  const [loadingPerfil, setLoadingPerfil] = useState(false);
  const [checkCidade, setCheckCidade] =
  useState<any>('');
  const [checkUf, setCheckUf] = useState<any>();

  const [perfilID, setIdPerfil] = useState<number>(0);
  const [etapa, setEtapa] = useState<number>(0);
  const [tipoCadastro, setTipoCadastro] = useState<number>(1);
  const [tipoSolicitacaoIntencaoMissa, setTipoSolicitacaoIntencaoMissa] = useState<number>(38);
  const [estado, setEstado] = useState<string>('');
  const [dataSelecionada, setDataSelecionada] = useState<number>(10);

  const [tiposInteracaoMissa, setTiposInteracaoMissa] = useState<IGetAllTipoIntencao[]>([]);
  const [suggestionsEstado, setSuggestionsEstado] = React.useState<ICidade[]>(UFs.UF,);
  const [cidades, setCidades] = useState<ICidade[]>([]);
  const [diaSemana, setDiaSemana] = useState<IHorarioDiaSemana[]>([]);


  const handleSelectFilter = (value: string) => {
    setSelectedFilter(value);
    //setSelectedFilterIndicador(value);
    formRef.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
  };

  const getPessoaByDocumento = async (documento: string) => {
    setRespostaErroBuscaPerfil('');
    setLoadingTipoPerfil(true);
    const service = new Services();

    const { error, response } = await service.getPessoaByDocumento(documento);

    if (!error && response) {
      setPerfis(response.pessoa.perfis);
      if (response.pessoa.perfis.length <= 0) {
        setRespostaErroBuscaPerfil(
          'Não existe perfil cadastrado para este CPF/CNPJ',
        );
      }
      if (!error && response) {
        setStatePessoa({
          Nome: response.pessoa.nome,
          id: response.pessoa.id,
        });
        setPerfis(response.pessoa.perfis);
        setViewConfirmation(true);
      }
    } else {
      setViewConfirmation(false);
      setRespostaErroBuscaPerfil(
        'Não existe uma pessoa cadastrada para este CPF/CNPJ',
      );
    }
    setLoadingTipoPerfil(false);
    clearDataPerfil();
  };

  const getPessoaByID = async (id: number) => {
    setRespostaErroBuscaPerfil('');
    setLoadingTipoPerfil(true);
    const service = new Services();

    const { error, response } = await service.getPessoaByID(id);

    if (!error && response) {
      setPerfis(response.pessoa.perfis);
      if (response.pessoa.perfis.length <= 0) {
        setRespostaErroBuscaPerfil('Não existe perfil cadastrado para este ID');
      }
      if (!error && response) {
        setStatePessoa({
          Nome: response.pessoa.nome,
          id: response.pessoa.id,
        });
        setPerfis(response.pessoa.perfis);
        setViewConfirmation(true);
      }
    } else {
      setRespostaErroBuscaPerfil(
        'Não existe uma pessoa cadastrada para este ID',
      );
      setViewConfirmation(false);
    }
    setLoadingTipoPerfil(false);

    clearDataPerfil();
  };

  const debounceOnChange = (value: string) => {
    formRef.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
    if (selectedFilter === 'CPF/CNPJ') {
      debounceGetPessoaByDocumento(value);
    } else if (selectedFilter === 'ID') {
      debounceGetPessoaByID(parseInt(value));
    }
  };

  const debounceGetPessoaByDocumento = useDebounce(getPessoaByDocumento, 1000);
  const debounceGetPessoaByID = useDebounce(getPessoaByID, 1000);


  const loadPerfil = useCallback(async perfilID => {
    setLoadingPerfil(true);
    const services = new Services();
    const { error, response } = await services.GetPerfilByID({ id: perfilID });

    if (!error && response) {

      const perfils: IPerfils = {contato_offline: response.contato_offline,};
      setCheckCidade(perfils.contato_offline.cidade_id)
      setCheckUf(perfils.contato_offline.estado_id)
      //checkTipoOperacao(perfilTriagem.debito_automatico.operacao)

      formRef.setFieldsValue({
        estado_descricao: response.contato_offline.estado_descricao,
        cidade_id: response.contato_offline.cidade_descricao,
        contato_offline: response.contato_offline,
      });
      setLoadingPerfil(false);
    } else {
      console.log('error', error);
    }
  }, []);


  const loadTiposInteracao = async () => {
    const services = new Services();
    const { error, response } = await services.GetAllTipoIntencao();

    if (!error && response) {
      setTiposInteracaoMissa(response);

    }
  };

  async function onSubmitCadastrados(values: any) {
    setLoadingCreateIntencao(true);

    console.log(values);

    const createIntencao: IRequestCreateIntencaoMissa = {
      //horario: values.horario,
     // dia_semana: values.dia_semana,
     descricao: values.descricao,
     nome_completo: values.nome,
     data_intencao_missa: values.data_intencao_missa,
     id_horario_dias_semana: values.id_horario_dias_semana,
     id_perfil: perfilID,
     id_cidade: checkCidade,
     id_estado: checkUf,
     id_tipo_solicitacao: tipoSolicitacaoIntencaoMissa,
     id_tipo_intencao_missa: values.id_tipo_intencao_missa,
    };

    const service = new Services();
    const { error, response } = await service.CreateIntencao(
      createIntencao,
    );

    //setLoadingCreate(false);

    if (!error) {
      enqueueSnackbar(`Cadastro finalizado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCreateIntencao(false);
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCreateIntencao(false);
    }
  }

  async function onSubmitNaoCadastrados(values: any) {
    setLoadingCreateIntencao(true);

    console.log(values);

    const createIntencao: IRequestCreateIntencaoMissa = {
      //horario: values.horario,
     // dia_semana: values.dia_semana,
      descricao: values.descricao,
      nome_completo: values.nome,
      data_intencao_missa: values.data_intencao_missa,
      id_horario_dias_semana: values.id_horario_dias_semana,
      id_perfil: perfilID,
      id_cidade: values.cidade_id,
      id_estado: values.estado_id,
      id_tipo_solicitacao: tipoSolicitacaoIntencaoMissa,
      id_tipo_intencao_missa: values.id_tipo_intencao_missa,
    };

    const service = new Services();
    const { error, response } = await service.CreateIntencao(
      createIntencao,
    );

    //setLoadingCreate(false);

    if (!error) {
      enqueueSnackbar(`Cadastro finalizado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCreateIntencao(false);
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCreateIntencao(false);
    }
  }



  const getCidades = async () => {
    const service = new Services();
    const getCidadesByUFResponse =
      await service.getCidadesByUF(estado);
    if (!getCidadesByUFResponse.error) {
      setCidades(getCidadesByUFResponse.response);
    }
  };

  const loadHorarioDiaSemana = async () => {
    const services = new Services();
    const { error, response } = await services.GetHorarioDiaSemana(diaSemanaSelecionada,);

    if (!error && response) {
      setDiaSemana(response);
    } else {
      console.log('ENTROU AQUI');
        return 0;
    }
  };

  async function pegarDiaSemana(data_intencao_missa: Date){

    const dataModificada = new Date(data_intencao_missa);
    console.log('DATA1 ', dataModificada);

    const dataExtensa = addDays(dataModificada, 1);
    console.log('DATA2 ', dataExtensa);

    const diaDaSemana = dataExtensa.getDay();
    console.log('DATA3 ', diaDaSemana);

    diaSemanaSelected = diaDaSemana;
    console.log('DATA3 ', diaSemanaSelected);

    if (diaSemanaSelected === 1){
      diaSemanaSelecionada = "Segunda-Feira";
    }else if (diaSemanaSelected === 2){
      diaSemanaSelecionada = "Terça-Feira";
    }else if (diaSemanaSelected === 3){
      diaSemanaSelecionada = "Quarta-Feira";
    }else if (diaSemanaSelected === 4){
      diaSemanaSelecionada = "Quinta-Feira";
    }else if (diaSemanaSelected === 5){
      diaSemanaSelecionada = "Sexta-Feira";
    }else if (diaSemanaSelected === 6){
      diaSemanaSelecionada = "Sábado";
    }else if (diaSemanaSelected === 0){
      diaSemanaSelecionada = "Domingo";
    }

    loadHorarioDiaSemana();
    clearDataHorario();
  };

  const clearDataPerfil = () => {
    formRef.setFieldsValue({
      estado_descricao: '',
      cidade_id: '',
      id_perfil: '',
    });
  };

  const clearDataHorario = () => {
    formRef.setFieldsValue({
      id_horario_dias_semana: '',
    });
  };


  const clearData = () => {
    formRef.setFieldsValue({
      estado_descricao: '',
      estado_id: '',
      cidade_id: '',
      id_perfil: '',
      nome: '',
      id_tipo_intencao_missa: '',
      data_intencao_missa: '',
      id_horario_dias_semana: '',
      descricao: '',
      cpf: ''
    });
  };

  useEffect(() => {
   loadTiposInteracao();
    window.document.title = 'CRM - Intenção Missa';
  }, []);

  return (
    <Grid container>
      <Grid
        container
        item
        sm={12}
        xs={12}
        style={{ marginTop: 20 }}
      >

        <Grid container item lg={5} sm={5} xs={12} style={{ padding: 0 }}>

          <Grid item container justify="space-around" sm={12} xs={12}>
            <Radio.Group >
              <Radio  onClick={(e: any) => {setTipoCadastro(1); setEtapa(1); clearData();}} value={1}>Cadastrados</Radio>
              <Radio  onClick={(e: any) => {setTipoCadastro(2); setEtapa(1);clearData();}} value={2}>Não Cadastrados</Radio>
            </Radio.Group>
          </Grid>
        </Grid>

        {/* FORMULARIO  */}

        {/* PESSOAS CADASTRADOS */}
        <InvisibleContent visible={tipoCadastro === 1}>
          <Grid item sm={12} xs={12}>
            <Spin
              size="large"
              spinning={loadingCreateIntencao}
              tip="Enviando dados..."
            >
              <Form
                form={formRef}
                size="middle"
                layout="vertical"
                scrollToFirstError={true}
                onFinish={onSubmitCadastrados}
                labelAlign="left"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                requiredMark={false}
                initialValues={{
                  estado_descricao: '',
                  cidade_id: '',
                  id_perfil: '',
                  id_tipo_intencao_missa: '',
                  data_intencao_missa: '',
                  id_horario_dias_semana: '',
                  descricao: '',
                  cpf: ''
                }}
                >

                <Grid container item lg={12} sm={12} xs={12} style={{ paddingTop: 10 }}>
                  <Grid container item lg={5} sm={5} xs={12} style={{ padding: 0 }}>
                    <Spin spinning={loadingTipoPerfil} tip="Buscando perfil">
                      <Spin spinning={loadingPerfil} tip="Buscando estado e cidade">
                        <Grid container item lg={12} sm={12} xs={12}>

                          <Grid
                            item
                            md={4}
                            lg={4}
                            xs={12}
                            style={{ paddingLeft: 10, paddingRight: 10 }}
                          >
                            Filtrar pessoa
                              <SelectAntd
                                style={{ width: '100%' }}
                                onChange={(e: string) => {
                                  setValueInput('');
                                  handleSelectFilter(e);
                                  setViewConfirmation(false);
                                  formRef.setFieldsValue({
                                    cpf: undefined,
                                  });
                                  setEnableSearchForm(true);
                                }}
                              >
                                {optionsFilter.map((option, index) => (
                                  <SelectAntd.Option key={option} value={option}>
                                    {option}
                                  </SelectAntd.Option>
                                ))}
                              </SelectAntd>
                          </Grid>

                          <Grid
                            item
                            md={8}
                            lg={8}
                            xs={12}
                            style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}
                          >
                            <Form.Item
                              name="cpf"
                              rules={[
                                {
                                  required: true,
                                  message: 'Digite um documento',
                                },
                              ]}
                            >
                              <Input
                                disabled={!enableSearchForm}
                                minLength={14}
                                maxLength={18}
                                name={selectedFilter}
                                value={valueInput}
                                type={
                                  selectedFilter === 'ID' || selectedFilter === 'ID AFIPE'
                                  ? 'number'
                                  : 'text'
                                }
                                onChange={(e: any) => {
                                  setViewConfirmation(false);
                                  if (selectedFilter === 'CPF/CNPJ') {
                                    formRef.setFieldsValue({
                                      cpf: MaskCPFeCNPJ(e.target.value).valueModify,
                                    });
                                  }
                                  if (
                                    selectedFilter === 'CPF/CNPJ' &&
                                    e.target.value.length >= 14
                                  ) {
                                    debounceOnChange(e.target.value);
                                  } else if (selectedFilter !== 'CPF/CNPJ') {
                                    debounceOnChange(e.target.value);
                                  }
                                }}
                              />
                            </Form.Item>
                          </Grid>
                        </Grid>

                        <div style={{color: "#FF0000"} }>
                          {respostaErroBuscaPerfil}
                        </div>

                        <Grid
                          item
                          lg={12}
                          xs={12}
                          style={{ paddingRight: 10, paddingLeft: 10 }}
                        >
                          <Form.Item
                            label='Nome'
                          >
                            <Input
                              disabled
                              value= {statePessoa.Nome}
                            />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={12} xs={12} style={{ paddingRight: 10, paddingLeft: 10 }}>
                          <Form.Item
                          name="id_perfil"
                          label="Tipo de perfil"
                          rules={[
                            {
                              required: true,
                              message: 'Selecione um perfil',
                            },
                          ]}
                          style={{ paddingTop: 0 }}
                        >
                          <SelectAntd
                            disabled={perfis.length <= 0}
                            placeholder="Tipo de perfil"
                            onChange={(id: number) => {
                              setIdPerfil(id);
                              loadPerfil(id)
                            }}
                          >
                            {perfis.map((item, index) => (
                              <SelectAntd.Option key={index} value={item.perfil_id}>
                                {item.tipo_perfil_descricao}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                        </Grid>

                        <Grid container>
                          <Grid item lg={4} xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <Form.Item
                              label= 'Estado'
                              name="estado_descricao"
                              rules={[
                                { required: true, message: 'Campo obrigatorio' },
                              ]}
                            >
                              <SelectAntd
                                style={{ width: '100%' }}
                                disabled
                              >
                              </SelectAntd>
                            </Form.Item>
                          </Grid>

                          <Grid item lg={8} xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <Form.Item
                              label= 'Cidade'
                              name="cidade_id"
                              rules={[
                                { required: true, message: 'Campo obrigatorio' },
                              ]}
                            >
                              <SelectAntd
                                style={{ width: '100%' }}
                                disabled
                              >
                              </SelectAntd>
                            </Form.Item>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          item
                          md={12}
                          xs={12}
                        >
                          <Grid item md={5} xs={12} style={{ paddingTop: 10 }}>
                            <ButtonComponent
                              color="secondary"
                              fullWidth

                              disabled={etapa !== 1}
                              //loading={loadingCreateSolicitacao}
                              onClick={() => {
                                setEtapa(2);
                              }}
                            >
                              Seguinte
                            </ButtonComponent>
                          </Grid>
                        </Grid>
                      </Spin>
                    </Spin>
                  </Grid>

                  <Grid container item lg={1} sm={1} xs={12} style={{ padding: 0 }}></Grid>

                  <Grid container item lg={5} sm={5} xs={12} style={{ padding: 0 }}>
                    <Grid
                      item
                      md={12}
                      lg={12}
                      xs={12}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Form.Item
                        label=' Tipo solicitação'                    >
                        <Input
                        value={'Intenção Missa'}
                        disabled
                        />
                      </Form.Item>
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      xs={12}
                      style={{ paddingRight: 10, paddingLeft: 10 }}
                    >
                      <Form.Item
                        label='Tipo intenção'
                        name="id_tipo_intencao_missa"
                        rules={[
                          { required: true, message: 'Campo obrigatorio' },
                        ]}
                      >
                        <SelectAntd
                          style={{ width: '100%' }}
                          disabled={etapa !== 2}
                        >
                          {tiposInteracaoMissa.map((option, index) => (
                            <SelectAntd.Option key={option.id} value={option.id}>
                              {option.descricao}
                            </SelectAntd.Option>
                          ))}
                        </SelectAntd>
                      </Form.Item>

                    </Grid>

                  <Grid container>
                    <Grid item lg={6} xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                      <Form.Item name="data_intencao_missa" label="Data agendada"
                        rules={[
                          {
                            required: true,
                            message: 'Selecione uma data agendada',
                          },
                        ]}
                      >
                          <Input type="date"  onChange={(e: any) => {pegarDiaSemana(e.target.value) }} disabled={etapa !== 2}/>
                      </Form.Item>
                    </Grid>

                    <Grid item lg={6} xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                      <Form.Item name="id_horario_dias_semana" label="Horário agendado"
                          rules={[
                            {
                              required: true,
                              message: 'Selecione um horário',
                            },
                          ]}
                        >
                          <SelectAntd style={{ width: '100%' }}>
                            {diaSemana.map((option, index) => (
                              <SelectAntd.Option key={option.id} value={option.id}>
                                {option.horario}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} xs={12} style={{ paddingRight: 10, paddingLeft: 10 }}>
                      <Form.Item
                        label='Descrição'
                        name="descricao"
                      >
                        <Input style={{height: 50}}  disabled={etapa !== 2} />
                      </Form.Item>
                    </Grid>

                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      item
                      md={12}
                      xs={12}
                    >
                      <Grid item md={5} xs={12} style={{ paddingTop: 10 }}>
                        <ButtonComponent
                          color="default"
                          fullWidth
                          onClick={clearData}
                        >
                          Cancelar
                        </ButtonComponent>
                      </Grid>

                      <Grid item md={1} xs={12} style={{ paddingTop: 10 }}></Grid>

                      <Grid item md={5} xs={12} style={{ paddingTop: 10 }}>
                        <ButtonComponent
                          color="primary"
                          fullWidth
                          type="submit"
                        >
                          Solicitar
                        </ButtonComponent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Spin>
          </Grid>
        </InvisibleContent>


        {/* PESSOAS NÃO CADASTRADOS */}

        <InvisibleContent visible={tipoCadastro === 2}>
          <Grid item sm={12} xs={12}>
            <Spin
              size="large"
              spinning={loadingCreateIntencao}
              tip="Enviando dados..."
            >
              <Form
                form={formRef}
                size="middle"
                layout="vertical"
                scrollToFirstError={true}
                onFinish={onSubmitNaoCadastrados}
                labelAlign="left"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                requiredMark={false}
                initialValues={{
                  estado_id: '',
                  cidade_id: '',
                  nome: '',
                  id_tipo_intencao_missa: '',
                  data_intencao_missa: '',
                  id_horario_dias_semana: '',
                  descricao: '',
                }}
              >
                <Grid container item lg={12} sm={12} xs={12} style={{ paddingTop: 0 }}>
                  <Grid container item lg={5} sm={5} xs={12} style={{ padding: 0 }}>

                    <Grid
                      item
                      lg={12}
                      xs={12}
                      style={{ paddingRight: 10, paddingLeft: 10 }}
                    >
                      <Form.Item
                        label='Nome'
                        name="nome"
                        rules={[
                          { required: true, message: 'Campo obrigatorio' },
                          { min: 3, message: 'Minimo de 3 letras' },
                          {
                            pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀ ÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                            message: 'Somente letras'
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Grid>

                    <Grid container>
                      <Grid item lg={4} xs={12} style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 150 }}>
                        <Form.Item
                          label= 'Estado'
                          name="estado_id"
                          rules={[
                            { required: true, message: 'Campo obrigatorio' },
                          ]}
                        >
                          <SelectAntd
                            style={{ width: '100%' }}
                            onChange={(estadoSelected: string) => {
                              setEstado(estadoSelected);
                              getCidades();
                            }}
                          >
                            {suggestionsEstado.map((option, index) => (
                              <SelectAntd.Option key={option.label} value={option.label}>
                                {option.label}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item lg={8} xs={12} style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 150 }}>
                        <Form.Item
                          label= 'Cidade'
                          name="cidade_id"
                          rules={[
                            { required: true, message: 'Campo obrigatorio' },
                          ]}
                        >
                          <InputCidades
                            ufProps={estado}
                            notUseLabel={true}
                            notUseFeedback={true}
                          />
                        </Form.Item>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      item
                      md={12}
                      xs={12}
                    >
                      <Grid item md={5} xs={12} style={{ paddingTop: 0 }}>
                        <ButtonComponent
                          color="secondary"
                          fullWidth
                          disabled={etapa !== 1}
                          onClick={() => {
                            setEtapa(2);
                          }}
                        >
                          Seguinte
                        </ButtonComponent>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item lg={1} sm={1} xs={12} style={{ padding: 0 }}></Grid>

                  <Grid container item lg={5} sm={5} xs={12} style={{ padding: 0 }}>
                    <Grid
                      item
                      md={12}
                      lg={12}
                      xs={12}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Form.Item
                        label=' Tipo solicitação'
                      >
                        <Input
                        value={'Intenção'}
                        disabled
                        />
                      </Form.Item>
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      xs={12}
                      style={{ paddingRight: 10, paddingLeft: 10 }}
                    >
                      <Form.Item
                        label='Tipo intenção'
                        name="id_tipo_intencao_missa"
                        rules={[
                          { required: true, message: 'Campo obrigatorio' },
                        ]}
                      >
                        <SelectAntd
                          style={{ width: '100%' }}
                          disabled={etapa !== 2}
                        >
                          {tiposInteracaoMissa.map((option, index) => (
                            <SelectAntd.Option key={option.id} value={option.id}>
                              {option.descricao}
                            </SelectAntd.Option>
                          ))}
                        </SelectAntd>
                      </Form.Item>
                    </Grid>

                    <Grid container>
                      <Grid item lg={6} xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Form.Item name="data_intencao_missa" label="Data agendada"
                          rules={[
                            {
                              required: true,
                              message: 'Selecione uma data agendada',
                            },
                          ]}
                        >
                          <Input type="date"
                            onChange={(e: any) => {pegarDiaSemana(e.target.value);}}
                            disabled={etapa !== 2}
                          />
                        </Form.Item>
                      </Grid>

                      <Grid item lg={6} xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Form.Item name="id_horario_dias_semana" label="Horário agendado"
                          rules={[
                            {
                              required: true,
                              message: 'Selecione um horário',
                            },
                          ]}
                        >
                          <SelectAntd style={{ width: '100%' }}>
                            {diaSemana.map((option, index) => (
                              <SelectAntd.Option key={option.id} value={option.id}>
                                {option.horario}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} xs={12} style={{ paddingRight: 10, paddingLeft: 10 }}>
                      <Form.Item
                        label='Descrição'
                        name="descricao"
                      >
                        <Input style={{height: 50}}  disabled={etapa !== 2} />
                      </Form.Item>
                    </Grid>

                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      item
                      md={12}
                      xs={12}
                    >
                      <Grid item md={5} xs={12} style={{ paddingTop: 10 }}>
                        <ButtonComponent
                          color="default"
                          fullWidth
                          onClick={clearData}
                        >
                          Cancelar
                        </ButtonComponent>
                      </Grid>

                      <Grid item md={1} xs={12} style={{ paddingTop: 10 }}></Grid>

                      <Grid item md={5} xs={12} style={{ paddingTop: 10 }}>
                        <ButtonComponent
                          color="primary"
                          fullWidth
                          type="submit"
                        >
                          Solicitar
                        </ButtonComponent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Spin>
          </Grid>
        </InvisibleContent>
      </Grid>
    </Grid>
  );
};

export default SolicitacaoIntencao;
