import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import api from '@http/api';
import { useAuth } from '@hooks/AuthenticateContext';

import { Checkbox, Grid, IconButton, Card } from '@material-ui/core';
import { AddBox, ClearRounded, AddOutlined, Edit, VisibilityOff, TuneOutlined} from '@material-ui/icons';
import Button from '@components/Shared/Buttons/Button';
import { FormatDate } from '@utils/formats';
import { Form, Select as SelectAntd,  Input, Spin, Radio, Switch } from 'antd';
import {  addDays } from 'date-fns';

import Filtros from '@src/components/Shared/Filtros';
import TableCustomKeysNoPagination from '@src/components/Shared/Tables/TableCustomKeysNoPagination';
//import generatePDF from 'react-to-pdf';

import Services, {
  ILista,
  ILista2,
  IHorarioDiaSemana,
} from './services';


const columnsLabel = {
  id: 'ID',
  nomeCompleto: 'Nome',
  estadoId: 'Estado',
  cidadeId: 'Cidade',
  tipoIntencaoMissa: 'Intenção',
  dataAgendada: 'Data agendada',
  horarioAgendado: 'Horário',
  descricao: 'Descrição',
};

let diaSemanaSelected: any;
let diaSemanaSelecionada: any;
let diaSelect: any;
let conteudo: any;
let nav: any;

const SolicitacaoIntencaoMissa: React.FC = () => {
  const [formRef] = Form.useForm();
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<ILista[]>([]);
  const [intencoes, setIntencoes] = useState<ILista[]>([]);
  const [tipoSolicitacaoIntencaoMissa, setTipoSolicitacaoIntencaoMissa] = useState<any>(38);
  const [diaSemana, setDiaSemana] = useState<IHorarioDiaSemana[]>([]);

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loading, setLoading] = useState(true);

  const [filtros, setFiltros] = useState(false);
  const [horarioSelect, setHorarioSelect] = useState<string>('');

  var conteudoResposta = JSON.parse(JSON.stringify(intencoes));
  conteudo = data.length <= 0

  const loadAllIntencoes = async () => {
    setLoading(true);
    const services = new Services();
    const { error, response } = await services.GetListaAllIntencoesMissa(tipoSolicitacaoIntencaoMissa);

    if (!error && response) {
      setIntencoes(response);
    }

    setLoading(false);
  };

  const loadAllIntencoesFiltros = async () => {
    setLoading(true);
    const services = new Services();
    const { error, response } = await services.GetListaAllIntencoesMissaFiltros(tipoSolicitacaoIntencaoMissa, diaSelect, horarioSelect);

    if (!error && response) {
      setIntencoes(response);
    }

    setLoading(false);
  };




  async function loadListaAllIntencaoMissa() {
    setLoading(true);
    await api
      .get( `api/IntencaoMissa/GetAllIntencaoMissa?idTipoSolicitacao=${tipoSolicitacaoIntencaoMissa}`)

      .then(response => {
        const items = response.data;
        const itemData: ILista[] = items.map((item: ILista) => ({
          id: item.id,
          nomeCompleto: item.nomeCompleto,
          estadoId: item.estado.sigla,
          cidadeId: item.cidade.descricao,
          tipoIntencaoMissa: item.tipoIntencaoMissa.descricao,
          dataAgendada: FormatDate(item.dataIntencaoMissa),
          horarioAgendado: item.horarioDiasSemana.horario,
          descricao: item.descricao,
          //dataCadastro: FormatDate(item.dataCadastro),
        }));

        setData(itemData);
        setLoading(false);
      })
      .catch(error => {
        alert(error);
      });
  }

  async function loadListaAllIntencaoMissaFiltros() {
    setLoading(true);
    await api
      .get( `api/IntencaoMissa/GetAllIntencaoMissa?idTipoSolicitacao=${tipoSolicitacaoIntencaoMissa}&dataIntencao=${diaSelect}&horario=${horarioSelect}`)

      .then(response => {
        const items = response.data;
        const itemData: ILista[] = items.map((item: ILista) => ({
          id: item.id,
          nomeCompleto: item.nomeCompleto,
          estadoId: item.estado.sigla,
          cidadeId: item.cidade.descricao,
          tipoIntencaoMissa: item.tipoIntencaoMissa.descricao,
          dataAgendada: FormatDate(item.dataIntencaoMissa),
          horarioAgendado: item.horarioDiasSemana.horario,
          descricao: item.descricao,
          //dataCadastro: FormatDate(item.dataCadastro),
        }));

        setData(itemData);
        setLoading(false);
      })
      .catch(error => {
        alert(error);
      });
  }



  const downloadFile = async () => {
    try {

      const createSolicitacao: ILista2 = {
        intencoes_missa:
        conteudoResposta
      };



      const response = await fetch('https://afipe-trismeg.azurewebsites.net/api/IntencaoMissa/GerarLista', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

          'Authorization': `Bearer ${user.token}`, // Exemplo de token
        },
         body: JSON.stringify(createSolicitacao)
      });


      console.log('meud deddus', response)

     if (response.ok) {
        // Obter o nome do arquivo a partir do cabeçalho "Content-Disposition"
        const disposition = response.headers.get('Content-Disposition');
        const filename = disposition && disposition.split('filename=')[1]?.replace(/"/g, '');

        // Criar um Blob com os dados binários recebidos
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);


        const link = document.createElement('a');
        link.href = url;
        link.download = filename || 'arquivo';
        //document.body.appendChild(link);
        link.click();

        // Limpar o link criado
        //document.body.removeChild(link);
        window.URL.revokeObjectURL(url);


        console.log("bob", blob)
        console.log("LINk", link)
        console.log("URL", url)
        console.log("response", response)
        //console.log("URL", body)
      } else {
        console.error('Erro ao gerar o arquivo: ', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao realizar o download: ', error);
    }
  };



  async function pegarDiaSemana(data_intencao: Date){

    diaSelect = new Date (data_intencao).toISOString();

    const dataModificada = new Date (data_intencao);

    const dataExtensa = addDays(dataModificada, 1);

    const diaDaSemana = dataExtensa.getDay();

    diaSemanaSelected = diaDaSemana;

    if (diaSemanaSelected === 1){
      diaSemanaSelecionada = "Segunda-Feira";
    }else if (diaSemanaSelected === 2){
      diaSemanaSelecionada = "Terça-Feira";
    }else if (diaSemanaSelected === 3){
      diaSemanaSelecionada = "Quarta-Feira";
    }else if (diaSemanaSelected === 4){
      diaSemanaSelecionada = "Quinta-Feira";
    }else if (diaSemanaSelected === 5){
      diaSemanaSelecionada = "Sexta-Feira";
    }else if (diaSemanaSelected === 6){
      diaSemanaSelecionada = "Sábado";
    }else if (diaSemanaSelected === 0){
      diaSemanaSelecionada = "Domingo";
    }


    loadHorarioDiaSemana();
    clearHorario();
  };

  const loadHorarioDiaSemana = async () => {
      const services = new Services();
      const { error, response } = await services.GetHorarioDiaSemana(
        diaSemanaSelecionada,
      );

      if (!error && response) {
        setDiaSemana(response);
        //formRef.setFieldsValue({ convenio: response[0]?.id });

        //console.log('TESTE', response);

        //return response[0]?.id;
      } else {
        console.log('ENTROU AQUI');

        //formRef.setFieldsValue({ convenio: null });
        return 0;
      }
  };

  const handleOpen = () => {
    setFiltros(!filtros);
  };

  const clearData = () => {
    formRef.setFieldsValue({
      data_intencao: '',
    });
    console.log('ENTROU AQUI');
  };

  const clearHorario = () => {
    formRef.setFieldsValue({
      horario: '',
    });
  };

  useEffect(() => {
    loadListaAllIntencaoMissa();
    loadAllIntencoes();

    window.document.title = 'CRM - Lista Intenções de missa';
  }, []);


  return (
    <Grid container>
      <Grid
        container
        item
        sm={12}
        //justify="space-around"
        xs={12}
        lg={12}
        style={{ marginTop: 20 }}
      >
        <Grid item lg={12} sm={12} xs={12}>

          {/* Botões  */}
          <Grid container item lg={10} sm={10} xs={10} style={{ paddingTop: 10 }}>
            <Grid
              item
              md={8}
              lg={8}
              xs={6}
              style={{
                paddingRight: 5,
                paddingLeft: 5,
                alignSelf: 'flex-end',
                paddingTop: 10,
              }}
            >
              <Button
                color="secondary"
                fullWidth
                style={{
                  height: 46,
                  width: 200
                }}
                onClick={handleOpen}
              >
                <TuneOutlined style={{ marginRight: 10 }} /> Filtros
              </Button>
            </Grid>

            <Grid
              item
              md={3}
              lg={3}
              xs={4}
              style={{
                paddingRight: 5,
                paddingLeft: 20,
                alignSelf: 'end',
                paddingTop: 10,
              }}
            >
              <Button
                color="primary"
                style={{
                  height: 46,
                  width: 200
                }}
                onClick={downloadFile}
                disabled={conteudo === true}
              >
                Gerar word
              </Button>
            </Grid>
          </Grid>

          {/* Filtros  */}
          <Filtros abrir={filtros} openMenu={handleOpen}>
            <Grid
              item
              md={12}
              lg={12}
              xs={12}
              style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
            >
             <Form className="filtros " layout={'vertical'} form={formRef}
               initialValues={{
                data_intencao: '',
                horario: '',
                }}
              >

                <Grid
                  item
                  md={12}
                  lg={12}
                  xs={12}
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    alignSelf: 'flex-end',
                    paddingTop: 10,
                  }}
                >
                  <Form.Item
                    name="data_intencao"
                  >
                    <p style={{color: '#fff'}}>Data agendada intenção missa:</p>
                    <Input type="date" onChange={(e: any) => {pegarDiaSemana(e.target.value);}}/>
                  </Form.Item>
                </Grid>

                <Grid
                  item
                  md={12}
                  lg={12}
                  xs={12}
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    alignSelf: 'flex-end',
                    paddingTop: 10,
                  }}
                >
                   <Form.Item
                    name="horario"
                  >
                    <SelectAntd
                      style={{ width: '100%' }}
                      onChange={(e: any) => {setHorarioSelect(e);}}
                    >
                      {diaSemana.map((option, index) => (
                        <SelectAntd.Option key={option.id} value={option.horario}>
                          {option.horario}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntd>
                  </Form.Item>
                </Grid>

                <Grid
                  item container
                  md={12}
                  lg={12}
                  xs={12}
                  style={{
                    paddingRight: 5,
                    paddingLeft: 5,
                    //alignSelf: 'flex-end',
                    //paddingTop: 10,
                  }}
                >
                  <Grid
                  item
                  md={5}
                  lg={5}
                  xs={12}
                  style={{
                    paddingRight: 5,
                    paddingLeft: 5,
                    //alignSelf: 'flex-end',
                    paddingTop: 10,
                  }}
                >
                  <Button
                    color="primary"
                    fullWidth
                    style={{
                      height: 46,
                    }}
                   // onClick={loadListaAllIntencaoMissaFiltros}
                    onClick={() => {loadListaAllIntencaoMissaFiltros(); loadAllIntencoesFiltros()}}

                  >
                   Buscar
                  </Button>
                </Grid>

                <Grid
                  item
                  md={5}
                  lg={5}
                  xs={12}
                  style={{
                    paddingRight: 5,
                    paddingLeft: 5,
                    //alignSelf: 'flex-end',
                    paddingTop: 10,
                  }}
                >
                  <Button
                    color="default"
                    fullWidth
                    style={{
                      height: 46,
                    }}
                    onClick={(e: any) => {loadListaAllIntencaoMissa(); clearData(); clearHorario();}}
                  >
                    Limpar filtros
                  </Button>
                </Grid>
                </Grid>


              </Form>
            </Grid>
          </Filtros>

          {/* Tabela  */}

          <Grid container item lg={9} sm={9} xs={12} style={{ padding: 0 }}>
            <Grid
              item
              lg={12}
              xs={12}
              style={{ paddingRight: 2, paddingLeft: 2, paddingTop: 5 }}
            >

              <TableCustomKeysNoPagination
                loading={loading}
                columns={[columnsLabel]}
                FieldsBold={['tipoIntencaoMissa']}
                data={data}
                keys={[
                  'id',
                  'nomeCompleto',
                  'estadoId',
                  'cidadeId',
                  'tipoIntencaoMissa',
                  'dataAgendada',
                  'horarioAgendado',
                  'descricao',
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SolicitacaoIntencaoMissa;
