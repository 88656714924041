import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import api from '@http/api';

import { Grid, IconButton, Card } from '@material-ui/core';
import { VisibilityOff, Search} from '@material-ui/icons';
import ButtonComponent from '@components/Shared/Buttons/Button';
import Button from '@components/Shared/Buttons/Button';
import { Form, Select as SelectAntd,  Input, Spin, Radio, Switch } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { FormatDate } from '@utils/formats';

import TableCustomKeysNoPagination from '@src/components/Shared/Tables/TableCustomKeysNoPagination';

import Services, {
  IGetAllHorariosTable,
  IRequestHorario,
} from './services';

const columnsLabel = {
  id: 'ID',
  diaSemana: 'Dia da semana',
  horario: 'Horário',
  dataCadastro: 'Data cadastro',
};

interface IDiasSemana {
  id: number;
  descricao: string;
}

const SolicitacaoIntencaoMissa: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IGetAllHorariosTable[]>([]);
  const [formref] = Form.useForm();

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingCreateHorario, setLoadingCreateHorario] = useState(false);
  const [habilitarBotao, setHabilitarBotao] = useState(false);

  const [diaSemanaSelect, setDiaSemanaSelect] = useState<any>();

  const [diasSemana, setDiasSemana] = useState<IDiasSemana[]>([
    { id: 1, descricao: 'Segunda-Feira' },
    { id: 2, descricao: 'Terça-Feira' },
    { id: 3, descricao: 'Quarta-Feira' },
    { id: 4, descricao: 'Quinta-Feira' },
    { id: 5, descricao: 'Sexta-Feira' },
    { id: 5, descricao: 'Sábado' },
    { id: 5, descricao: 'Domingo' },

  ]);

  async function loadGetAllHorarios() {
    setLoading(true);
    await api
      .get('api/HorarioDiasSemana/GetAllHorarios')
      .then(response => {
        const items = response.data;
        const itemData: IGetAllHorariosTable[] = items.map((item: IGetAllHorariosTable) => ({
          id: item.id,
          diaSemana: item.diaSemana,
          horario: item.horario,
          dataCadastro: FormatDate(item.dataCadastro),
        }));

        setData(itemData);
        setLoading(false);
      })
      .catch(error => {
        alert(error);
      });
  }

  async function loadGetHorariosBySemana() {
    setLoading(true);
    await api
      .get( `api/HorarioDiasSemana/GetAllHorariosByDiaSemana?DiaSemana=${diaSemanaSelect}`)

      .then(response => {
        const items = response.data;
        const itemData: IGetAllHorariosTable[] = items.map((item: IGetAllHorariosTable) => ({
          id: item.id,
          diaSemana: item.diaSemana,
          horario: item.horario,
          dataCadastro: FormatDate(item.dataCadastro),
        }));

        setData(itemData);
        setLoading(false);
      })
      .catch(error => {
        alert(error);
      });
  }

  async function onSubmit(values: any) {
    setLoadingCreate(true);
    console.log(values);

    const createSolicitacao: IRequestHorario = {
      horario: values.horario,
      dia_semana: values.dia_semana,
    };

    const service = new Services();
    const { error, response } = await service.CreateHorario(
      createSolicitacao,
    );

    setLoadingCreate(false);

    if (!error) {
      enqueueSnackbar(`Cadastro finalizado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCreateHorario(false);
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCreateHorario(false);
    }
  }

  const loadDesativarHorario = async (horario_id: number) => {
    const services = new Services();

    const recuseSolicitacaoResponse = await services.DesativarHorario({horario_id});

    if (!recuseSolicitacaoResponse.error) {
      setLoadingCreate(false);
      setLoading(false);
      enqueueSnackbar(`Solicitação aprovada com sucesso!`, {
        title: 'Solicitação aprovada com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    window.location.reload();

    } else {
      setLoadingCreate(false);
      setLoading(false);
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  const clearDados = () => {
    formref.setFieldsValue({
      dia_semana: '',
    });

    formref.setFieldsValue({
      horario: '',
    });
  };


  useEffect(() => {
    loadGetAllHorarios();
    window.document.title = 'CRM - Horários intenção de missa';
  }, []);

  return (
    <Grid container>
      <Grid
        container
        item
        sm={12}
        //justify="space-around"
        xs={12}
        lg={12}
        style={{ marginTop: 20 }}
      >
        {/* FORMULARIO  */}
        <Grid item lg={12} sm={12} xs={12}>
          <Spin
            size="large"
            spinning={loadingCreate}
            tip="Enviando dados..."
          >
            <Form
              form={formref}
              size="middle"
              layout="vertical"
              scrollToFirstError={true}
              onFinish={onSubmit}
              labelAlign="left"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              requiredMark={false}
              initialValues={{

                dia_semana: '',
                horario: '',
              }}
            >

              <Grid container item lg={12} sm={12} xs={12} style={{ paddingTop: 10 }}>

                {/* Lista dos horários cadastrados  */}
                <Grid container item lg={6} sm={6} xs={12} style={{ padding: 0 }}>
                  <Card style={{ padding: 20, borderRadius: 8, width: 550,  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'}}>
                    <Grid container
                      item
                      md={12}
                      lg={12}
                      xs={12}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >

                      <Grid
                        item
                        md={8}
                        lg={8}
                        xs={12}
                        style={{
                          paddingRight: 5,
                          paddingLeft: 5,
                          //alignSelf: 'flex-end',
                          paddingTop: 10,
                        }}
                      >
                        <SelectAntd
                          style={{ width: '100%' }}
                          placeholder="Dias da semana"
                          onChange={(e: any) => {setDiaSemanaSelect(e);}}
                        >
                          {diasSemana.map(item => (
                            <SelectAntd.Option key={item.id} value={item.descricao}>
                              {item.descricao}
                            </SelectAntd.Option>
                          ))}
                        </SelectAntd>

                      </Grid>

                      <Grid
                        item
                        md={2}
                        lg={2}
                        xs={12}
                        style={{
                          paddingRight: 5,
                          paddingLeft: 5,
                          //alignSelf: 'flex-end',
                          paddingTop: 10,
                        }}
                      >
                        <Button
                          color="secondary"
                          fullWidth
                          style={{
                            height: 32,
                          }}
                          onClick={loadGetHorariosBySemana}
                        >
                        <Search />
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      xs={12}
                      style={{ paddingRight: 2, paddingLeft: 2, paddingTop: 5 }}
                    >

                      <TableCustomKeysNoPagination
                        loading={loading}
                        columns={[columnsLabel]}
                        FieldsBold={['horario', 'diaSemana']}
                        data={data}
                        keys={[
                          'id',
                          'diaSemana',
                          'horario',
                          'dataCadastro',
                        ]}
                        ActionHeader={'Desativar'}
                        Actions={[
                          {
                            Button: (item: IGetAllHorariosTable) => {
                              return (
                                <Grid container justifyContent="center">
                                  <IconButton
                                    onClick={() => {loadDesativarHorario(item.id)}}
                                  >
                                    <VisibilityOff />
                                  </IconButton>
                                </Grid>
                              );
                            },
                          },
                        ]}
                      />
                    </Grid>
                  </Card>
                </Grid>

                {/* Novo cadastro de horário  */}
                <Grid container item lg={5} sm={5} xs={12} style={{ padding: 0 }}>
                  <Card style={{ padding: 20, borderRadius: 8, width: 450, boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
                    <Grid item lg={6} md={6} xs={5} style={{ paddingLeft: 250, paddingRight: 5, alignSelf: 'end' }}>
                      <ButtonComponent
                        fullWidth
                        style={{ height: 46, borderRadius: 5}}
                        color={"secondary"}
                        onClick={() => {
                          setHabilitarBotao(true)
                        }}
                      >
                        Adicionar novo horário
                      </ButtonComponent>
                    </Grid>

                    <Grid container>
                      <Grid item lg={12} xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Form.Item name="dia_semana" label="Dia da semana"
                          rules={[
                            {
                              required: true,
                              message: 'Selecione um dia da semana',
                            },
                          ]}
                        >
                          <SelectAntd
                            style={{ width: '100%' }}
                            disabled={habilitarBotao !== true}
                          >
                             {diasSemana.map(item => (
                          <SelectAntd.Option key={item.id} value={item.descricao}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item lg={12} xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Form.Item name="horario" label="Horário"
                          rules={[
                            {
                              required: true,
                              message: 'Adicione um horário',
                            },
                          ]}
                        >
                          <MaskedInput   disabled={habilitarBotao !== true} mask='11:11'/>
                        </Form.Item>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      item
                      lg={12}
                      md={12}
                      xs={12}
                    >
                      <Grid item lg={4} md={4} xs={12} style={{ paddingTop: 10, paddingLeft: 5, paddingRight: 5 }}>
                        <ButtonComponent
                          color="default"
                          fullWidth
                          disabled={habilitarBotao !== true}
                          onClick={clearDados}
                        >
                          Cancelar
                        </ButtonComponent>
                      </Grid>

                      <Grid item lg={4} md={4} xs={12} style={{ paddingTop: 10, paddingLeft: 5, paddingRight: 5 }}>
                        <ButtonComponent
                          color="primary"
                          fullWidth
                          type="submit"
                          disabled={habilitarBotao !== true}
                        >
                          Salvar
                        </ButtonComponent>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Spin>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SolicitacaoIntencaoMissa;
