import React, { useCallback, useEffect, useState } from 'react';

// import Table from "@components/Shared/Tables/TableActions"
import { TitlePage } from '@styles/styles.global';
import { Grid, IconButton } from '@material-ui/core';
import { Edit, AccountBoxOutlined } from '@material-ui/icons';

import ButtonComponent from '@src/components/Shared/Buttons/Button';
import Tag from '@components/Shared/Tag';
import Table from '@components/Shared/Tables/NoPagination';
import { useSnackbar } from 'notistack';

import PageUserService from './services';
import NewUserForm from './components/NewUserForm';
import EditUserForm from './components/EditUserForm';

import { useAuth } from '@hooks/AuthenticateContext';

interface IUser {
  id: number;
  role: string;
  nome: string;
}

const columnsLabel = {
  id: 'Código',
  role: 'Tipo',
  nome: 'Nome',
};

const Usuarios: React.FC = () => {
  const [loadingCadastro, setLoadingCadastro] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IUser[]>([]);

  const [newUserVisibility, setnewUserVisibility] = React.useState(false);
  const [editUserVisibility, seteditUserVisibility] = React.useState(false);

  const [id, setID] = React.useState(0);

  const handlenewUserVisibilityChange = () => {
    seteditUserVisibility(false);
    setnewUserVisibility(!newUserVisibility);
  };

  const handleEditUserVisibilityChange = (userID: number) => {
    setnewUserVisibility(false);
    setID(userID);
    seteditUserVisibility(true);
  };

  const handleDisableVisibilityChange = () => {
    setnewUserVisibility(false);
    seteditUserVisibility(false);
  };

  const handleRefreshComponent = () => {
    firstLoad();
    handleDisableVisibilityChange();
  };

  const firstLoad = useCallback(async () => {
    setLoading(true);

    const pageUserService = new PageUserService();
    const { error, response } = await pageUserService.ListUsuarios();

    if (!error && response) {
      setData(response);
      setLoading(false);
    } else {
      console.log('error', error);

      enqueueSnackbar('Erro ao carregar usuarios!', {
        title: 'Erro ao efetuar cadastro.',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  }, []);

  useEffect(() => {
    firstLoad();
    window.document.title = 'CRM - Usuários';
  }, []);

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <>
      <TitlePage>Usuário</TitlePage>
      <Grid container style={{ padding: 10 }}>
        <Grid item sm={6} xs={12}>
          <Grid container>
            <Grid item sm={3} xs={5} style={{}}>
              <Tag color="default">Cadastros</Tag>
            </Grid>
            <Grid item sm={5} xs={1}></Grid>
            <Grid item sm={3} xs={6}>
              <ButtonComponent
                color="secondary"
                disabled={loadingCadastro}
                loading={loadingCadastro}
                fullWidth
                onClick={handlenewUserVisibilityChange}
                style={{
                  height: 46,
                  visibility: newUserVisibility ? 'hidden' : 'visible',
                }}
              >
                <AccountBoxOutlined style={{ marginRight: 10 }} /> Novo Usuário
              </ButtonComponent>
            </Grid>
          </Grid>

          <Grid
            item
            sm={11}
            xs={12}
            style={{ paddingTop: 5, marginBottom: 25 }}
          >
            <Table
              loading={loading}
              columns={[columnsLabel]}
              data={data}
              Actions={[
                {
                  Button: (row: IUser) => {
                    return (
                      <IconButton
                        onClick={() => handleEditUserVisibilityChange(row.id)}
                      >
                        <Edit />
                      </IconButton>
                    );
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
        {/* Form */}
        <Grid item sm={3} xs={12}>
          {newUserVisibility && (
            <NewUserForm
              Cancel={handleDisableVisibilityChange}
              Done={handleRefreshComponent}
            />
          )}
          {editUserVisibility && (
            <EditUserForm
              Cancel={handleDisableVisibilityChange}
              id={id}
              Done={handleRefreshComponent}
            />
          )}
        </Grid>
        {/* FORMULARIO  */}
      </Grid>
    </>
  );
};

export default Usuarios;
