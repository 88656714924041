import api from '@http/api';
import { FormatDate } from '@src/utils/formats';

export interface IGetAllHorariosTable {
  //modelo: ISolicitacaoResultTable[];
  id: number;
  diaSemana: string;
  horario: string;
  dataCadastro: string;

}

export interface ISolicitacaoResultTable {
  id_correspondencia_devolvida: string;
}

export interface IGetAllHorarios {

  error: boolean;
  response?: IGetAllHorariosTable[];
  //modelo: IGetAllHorariosTable[];
}

export interface IRequestHorario {
  horario: string;
  dia_semana: string;
}

export interface IDesativarHorario {
  horario_id: number;
}

class Services {
  public async GetAllHorarios(): Promise<IGetAllHorarios> {
    return await api
      .get(`/api/HorarioDiasSemana/GetAllHorarios`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }


  public async CreateHorario(data: IRequestHorario) {
    return await api
      .post('/api/HorarioDiasSemana/CreateHorario', data)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }


  public async DesativarHorario({ horario_id }: IDesativarHorario) {
    return await api
      .patch('/api/HorarioDiasSemana/DesativarHorario', {
        horario_id,
      })

      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }


}

export default Services;
