import api from '@http/api';
import { FormatDate } from '@src/utils/formats';


export interface ILista {
   id: number;
  descricao: string;
  nomeCompleto: string;
  dataCadastro: string;
  dataExtracao: string;
  dataIntencaoMissa: string;
  dataIntencaoInicio: string;
  dataIntencaoFim: string;
  statusExtracao: number;
  perfilId: number;
  perfil: string;
  cidadeId: number;
  cidade: {
      id: number;
      estado: string;
      usuarioCadastro:string;
      descricao: string;
      dataCadastro: string;
      entidade: string;
  },
  estadoId: string;
  estado: {
      id: string;
      usuarioCadastro: string;
      descricao: string;
      sigla: string;
      dataCadastro: string;
      entidade: string;
      cidades: string;
      contatosOfflines: string;
  },
  usuarioCadastroId: string;
  usuarioCadastro: string;
  usuarioExtracaoId: string;
  usuarioExtracao: string;
  horarioDiasSemanaId: string;
  horarioDiasSemana: {
      id: string;
      horario: string;
      diaSemana: string;
      dataAlteracao: string;
      dataCadastro: string;
      statusAtivo: string;
      usuarioAlteracao: string;
      usuarioCadastro: string;
  },
  tipoSolicitacaoId: string;
  tipoSolicitacao: {
      id: string;
      descricao: string;
      formaContribuicao: string;
  },
  tipoIntencaoMissaId: string;
  tipoIntencaoMissa: {
    id: string;
    descricao: string;
    dataCadastro: string;
    statusAtivo: string;
    usuarioAlteracao: string;
    usuarioCadastro: string;
  },
}


export interface ILista2 {
  intencoes_missa: [
    {
  id: number;
 descricao: string;
 nomeCompleto: string;
 dataCadastro: string;
 dataExtracao: string;
 dataIntencaoMissa: string;
 dataIntencaoInicio: string;
 dataIntencaoFim: string;
 statusExtracao: number;
 perfilId: number;
 perfil: string;
 cidadeId: number;
 cidade: {
     id: number;
     estado: string;
     usuarioCadastro:string;
     descricao: string;
     dataCadastro: string;
     entidade: string;
 },
 estadoId: string;
 estado: {
     id: string;
     usuarioCadastro: string;
     descricao: string;
     sigla: string;
     dataCadastro: string;
     entidade: string;
     cidades: string;
     contatosOfflines: string;
 },
 usuarioCadastroId: string;
 usuarioCadastro: string;
 usuarioExtracaoId: string;
 usuarioExtracao: string;
 horarioDiasSemanaId: string;
 horarioDiasSemana: {
     id: string;
     horario: string;
     diaSemana: string;
     dataAlteracao: string;
     dataCadastro: string;
     statusAtivo: string;
     usuarioAlteracao: string;
     usuarioCadastro: string;
 },
 tipoSolicitacaoId: string;
 tipoSolicitacao: {
     id: string;
     descricao: string;
     formaContribuicao: string;
 },
 tipoIntencaoMissaId: string;
 tipoIntencaoMissa: {
  id: string;
  descricao: string;
  dataCadastro: string;
  statusAtivo: string;
  usuarioAlteracao: string;
  usuarioCadastro: string;
},
    }]
}




class Services {
  public async GetListaAllIntencoes(idTipoSolicitacao: string) {
    return await api
      .get<ILista[]>(`/api/IntencaoMissa/GetAllIntencaoMissa?idTipoSolicitacao=${idTipoSolicitacao}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetListaAllIntencoesMissaFiltros(idTipoSolicitacao: string, dataSelect: string) {
    return await api
      .get<ILista[]>(`/api/IntencaoMissa/GetAllIntencaoMissa?idTipoSolicitacao=${idTipoSolicitacao}&dataIntencaoInicio=${dataSelect}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async gerarListaIntecoesMissa(data: ILista2) {
    return await api
      .post('/api/IntencaoMissa/GerarLista')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }



}

export default Services;
