import api from '@http/api';
import { FormatDate } from '@src/utils/formats';
import { IPerfil } from '@interfaces/UserComplete';


export interface IResponseRequestPessoaByCPF {
  pessoa: {
    id: string;
    documento: string;
    nome: string;
    perfis: IPerfil[];
    tipos_perfis: string[];
  };
}

export interface IResponsePessoaByCPF {
  response?: {
    pessoa: {
      id: string;
      nome: string;
      documento: string;
      perfis: IPerfil[];
      tipos_perfis: string[];
    };
  };
  error: boolean;
}

export interface IContatoOffline {
  contato_offline_id: string;
  cidade_id: string;
  estado_id: string;
  estado_descricao: string;
  cidade_descricao: string;

}

interface IResponsePerfil {
  error: boolean;
  response?: IPerfils;
}

export interface IPerfils {
  contato_offline: IContatoOffline;

}

export interface IGetAllTipoIntencao{
  id: number;
  descricao: string;

}

export interface IRequestCreateIntencao{
  descricao: string;
  nome_completo: string;
  data_intencao_inicio: string,
  data_intencao_fim: string,
  id_perfil: number,
  id_cidade: number;
  id_estado: number;
  id_tipo_solicitacao: number;
  id_tipo_intencao_missa: number;
}

export interface IResponseGetCidadesByUf {
  cidade_id: string;
  cidade_nome: string;
}

export interface ICidade {
  id: string | number;
  label: string;
}

class Services {

  public async CreateIntencao(data: IRequestCreateIntencao) {
    return await api
      .post('/api/IntencaoMissa/CreateIntencao', data)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }



  public async getPessoaByID(id: number): Promise<IResponsePessoaByCPF> {
    return await api
      .get(`api/Pessoa/GetPessoaById/${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getPessoaByDocumento(
    documento: string,
  ): Promise<IResponsePessoaByCPF> {
    return await api
      .post<IResponseRequestPessoaByCPF>(`api/Pessoa/FindPessoaByCpf`, {
        documento,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }


  public async GetPerfilByID({ id }: any): Promise<IResponsePerfil> {
    return await api
      .get<IPerfils>(`api/Perfil/GetPerfilById/${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };

      })
      .catch(error => {
        return {
          error: true,
        };
      });

  }


  public async GetAllTipoIntencao() {
    return await api
      .get<IGetAllTipoIntencao[]>(`/api/TipoIntencaoMissa/GetAllTipoIntencaoMissa`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }


  public async getCidadesByUF(estado: string) {
    return await api
      .post<IResponseGetCidadesByUf[]>('/api/ContatoOffline/FindCidades', {
        uf: estado,
      })
      .then(response => {
        let reescrito: ICidade[] = [];

        response.data.map(cidade => {
          const obj: ICidade = {
            id: cidade.cidade_id,
            label: cidade.cidade_nome,
          };

          return reescrito.push(obj);
        });

        return {
          error: false,
          response: reescrito,
        };
      })
      .catch(() => {
        return {
          error: true,
          response: [],
        };
      });
  }



}

export default Services;
