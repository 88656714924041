import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '@http/api';

interface User {
  nome: string;
  sobrenome: string;
  nomeFantasia: string;
  role: string;
  telefonia: 'Zenvia' | 'Sem Integração' | 'Asterisk' | 'Leucotron' |null;
  id: number;
  ramal_usuario: string;
  ramal_numero: string;
  ramal: string;
  entidade_id: number;
  token: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  senha: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@amex:token');
    const user = localStorage.getItem('@amex:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, senha }) => {
    const response = await api.post('api/Home/login', {
      email,
      senha,
    });

    const { token, nome, sobrenome, role, id, entidade_id, telefonia, ramal, ramal_usuario, ramal_numero } =
      response.data.user;
    const nomeFantasia = nome.split(' ');
    const user: User = {
      nomeFantasia: nomeFantasia[0],
      nome,
      sobrenome,
      role,
      id,
      telefonia,
      entidade_id,
      ramal,
      ramal_usuario,
      ramal_numero,
      token
    };

    localStorage.setItem('@amex:token', token);
    localStorage.setItem('@amex:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    api.defaults.headers.entidade = entidade_id;

    setData({ token, user });
  }, []);


  const signOut = useCallback(() => {
    localStorage.removeItem('@amex:token');
    localStorage.removeItem('@amex:user');
    localStorage.removeItem('@amex:entidade');
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      setData({
        token: data.token,
        user,
      });
      localStorage.setItem('@amex:user', JSON.stringify(user));
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, updateUser, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
