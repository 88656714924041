import React from 'react';

import Cadastros from '@pages/Private/shared/ListPessoas';
import EditCadastro from '@pages/Private/shared/EditCadastro';
import NovoCadastro from '@src/Pages/Private/Cliente/shared/NovoCadastro';

import EditarUsuario from '@pages/Private/shared/EditarUsuario';
import ListaLigacao from '@pages/Private/Cliente/Operacional/ListaLigacao';
import WebPhoneAtivo from '@src/Pages/Private/Cliente/Operacional/WebPhoneAtivo';
import DoacoesMeta from '@pages/Private/Cliente/Operacional/DoacoesMeta';
import InserirDoacoes from '@src/Pages/Private/Cliente/Admin/InsercaoDoacoes';



import { ContactPage } from '@assets/Icons';
import { PhoneInTalk, FavoriteBorderOutlined, BarChart, LoyaltyOutlined  } from '@material-ui/icons';
//import ListCorrespondenciaDevolvida from '@src/Pages/Private/Cliente/Operacional/CorrespondenciaDevolvida';
import { ForwardToInbox, mdiDatabaseSearch, Article } from '@assets/Icons/';
import DashboardCadastros from '@pages/Private/Cliente/Operacional/Dashboard'

import TriagemCadastro from '@pages/Private/shared/Triagem/ListPessoas';
import EditCadastroTriagem from '@pages/Private/shared/Triagem/EditCadastro';

import WebPhoneReceptivo from '@src/Pages/Private/Cliente/Operacional/WebPhoneReceptivo';

export interface IRoute {
  index: number | null;
  path: string;
  component: React.ComponentType<any>;
  roles: string[];
}

export interface IRouteMenu {
  index: number;
  path?: string;
  Icon?: React.ReactNode;
  label: string;
  roles: string[];
  menu: 'lateral' | 'header';
  subMenu?: IRouteMenu[];
}

export const routesOperacional: IRoute[] = [
  {
    index: 1,
    path: '/home',
    component: DoacoesMeta,
    roles: ['OPERACIONAL - BASE'],
  },
  {
    index: 1,
    path: '/home',
    component: DashboardCadastros,
    roles: ['OPERACIONAL - BASE'],
  },

  {
    index: 2,
    path: '/cadastros/',
    component: Cadastros,
    roles: ['OPERACIONAL - BASE'],
  },
  {
    index: null,
    path: '/cadastros/triagem',
    component: TriagemCadastro,
    roles: ['OPERACIONAL - BASE'],
  },
  {
    index: 3,
    path: '/cadastros/novo',
    component: NovoCadastro,
    roles: ['OPERACIONAL - BASE'],
  },
  {
    path: '/cadastro/:idCadastro',
    component: EditCadastro,
    roles: ['OPERACIONAL - BASE'],
    index: null,
  },
  {
    path: '/cadastro/triagem/:idCadastro',
    component: EditCadastroTriagem,
    roles: ['OPERACIONAL - BASE'],
    index: null,
  },
  {
    path: '/ligacoes',
    component: ListaLigacao,
    roles: ['OPERACIONAL - BASE'],
    index: 6,
  },
  {
    index: null,
    path: '/usuario/editar',
    component: EditarUsuario,
    roles: ['OPERACIONAL - BASE'],
  },
  // {
  //   index: null,
  //   path: '/webphone/ativo',
  //   component: WebPhoneAtivo,
  //   roles: ['OPERACIONAL - BASE'],
  // },
];

export const routesOperacionalBaseMenu: IRouteMenu[] = [
  {
    index: 1,
    path: '/home',
    label: 'Dulcete',
    Icon: <FavoriteBorderOutlined />,
    roles: ['OPERACIONAL - BASE'],
    menu: 'header',
  },
  {
    index: 2,
    label: 'Cadastros',
    path: '/cadastros',
    Icon: ContactPage,
    roles: ['OPERACIONAL - BASE'],
    menu: 'lateral',
    subMenu: [
      {
        index: 2.2,
        path: '/cadastros/novo',
        label: 'Novo',
        roles: ['OPERACIONAL - BASE'],
        menu: 'lateral',
      },
      {
        index: 2.2,
        path: '/cadastros',
        label: 'Consulta',
        roles: ['OPERACIONAL - BASE'],
        menu: 'lateral',
      },
      {
        index: 2.3,
        path: '/cadastros/triagem',
        label: 'Consulta triagem',
        roles: ['OPERACIONAL - BASE'],
        menu: 'lateral',
      },
    ],
  },
  {
    index: 5,
    label: 'Ligações',
    path: '/ligacoes',
    Icon: PhoneInTalk,
    roles: ['OPERACIONAL - BASE'],
    menu: 'lateral',
  },
  {
    index: 6,
    label: 'Dashboard',
    path: '/home',
    Icon: <BarChart />,
    roles: ['OPERACIONAL - BASE'],
    menu: 'header',
  },


];
