import React from 'react';

import DoacoesShared from '@pages/Private/shared/Doacoes';
import ListPessoasShared from '@pages/Private/shared/ListPessoas';
import EditCadastro from '@pages/Private/shared/EditCadastro';
import Indicadores from '@pages/Private/shared/ListIndicadores';

import Usuarios from '@pages/Private/shared/Usuarios';
import EditarUsuario from '@src/Pages/Private/shared/EditarUsuario';
import ListCampanha from '@pages/Private/Amex/shared/ListCampanhas';
import DetalhesCampanha from '@pages/Private/Amex/shared/DetalhesCampanha';
import ConfigEstrategia from '@pages/Private/Amex/shared/ConfigEstrategias';

import { AccountBoxIcon, BookMarkIcon, ContactPage, StickyNote2, Article, mdiDatabaseSearch, mdiTextAccount} from '@src/Assets/Icons';
import DadosGeraisCadastros from '@src/Pages/Private/shared/Consultas';
import Financeiro from '@src/Pages/Private/Cliente/Admin/Financeiro';
import ImportarRetorno from '@src/Pages/Private/Cliente/Admin/ImportarRetorno';
import DetalhesRemessa from '@src/Pages/Private/Cliente/Admin/DetalhesRemessa';
import DetalhesHistoricoRemessa from '@src/Pages/Private/Cliente/Admin/DetalhesHistoricoRemessa';

import ListEstrategias from '@pages/Private/Cliente/Admin/ListEstrategias';
import DetalhesEstrategia from '@pages/Private/Cliente/Admin/DetalhesEstrategia';

import DashboardCadastros from '@src/Pages/Private/shared/Dashboard'
import InserirDoacoes from '@src/Pages/Private/Cliente/Admin/InsercaoDoacoes';
import NovoCadastro from '@src/Pages/Private/Cliente/shared/NovoCadastro';
import ListSolicitacoes from '@pages/Private/Cliente/Admin/ListSolicitacoes';
import TipoPerfil from '@pages/Private/Cliente/Admin/TiposPerfil';
import ListEnvios from '@pages/Private/Cliente/Admin/ListEnvios';
import ListCorrespondenciaDevolvida from '@src/Pages/Private/Cliente/Admin/CorrespondenciaDevolvida';
import ListaLigacao from '@pages/Private/Cliente/Operacional/ListaLigacao';
import Consultas from '@src/Pages/Private/shared/Consultas';
import HistoricoRemessa from '@pages/Private/Cliente/Admin/HistoricoRemessa';

import TriagemCadastro from '@pages/Private/shared/Triagem/ListPessoas';
import EditCadastroTriagem from '@pages/Private/shared/Triagem/EditCadastro';

//import DashboardCadastros from '@src/Pages/Private/shared/Dashboard'
//import Dashboard from '@src/Pages/Private/shared/DashboardG'
import DashboardComparativo from '@src/Pages/Private/shared/DashboardComparativo'
import SolicitacaoAtendimento from '@src/Pages/Private/shared/SolicitacaoAtendimento';
import CreateHorarios from '@src/Pages/Private/shared/SolicitacaoAtendimento/CreateHorarios';
import ListaAtendidos from '@src/Pages/Private/shared/SolicitacaoAtendimento/ListAtendidos';

import {
  FavoriteBorderOutlined,
  PaymentOutlined,
  PieChartOutlined,
  LoyaltyOutlined,
  SendOutlined,
  PhoneInTalk,
  BarChart

} from '@material-ui/icons';


export interface IRoute {
  index: number | null;
  path: string;
  component: React.ComponentType<any>;
  roles: string[];
}
export interface IRouteMenu {
  index: number;
  path?: string;
  Icon?: React.ReactNode;
  label: string;
  roles: string[];
  menu: 'lateral' | 'header';
  subMenu?: IRouteMenu[];
}

export const routesAdmin: IRoute[] = [
  {
    index: 1,
    path: '/home',
    component: DoacoesShared,
    roles: ['ADM SOCIALLIS'],
  },
  {
    index: 2,
    path: '/cadastros/consulta',
    component: ListPessoasShared,
    roles: ['ADM SOCIALLIS'],
  },
  {
    path: '/cadastro/:idCadastro',
    component: EditCadastro,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    path: '/cadastro/triagem/:idCadastro',
    component: EditCadastroTriagem,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    index: 3,
    path: '/cadastros/novo',
    component: NovoCadastro,
    roles: ['ADM SOCIALLIS'],
  },
  {
    path: '/cadastros/dadosGerais',
    component: DadosGeraisCadastros,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    index: null,
    path: '/cadastros/consulta/triagem',
    component: TriagemCadastro,
    roles: ['ADM SOCIALLIS'],
  },
  {
    path: '/cadastros/indicadores',
    component: Indicadores,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    index: null,
    path: '/usuarios/',
    component: Usuarios,
    roles: ['ADM SOCIALLIS'],
  },
  {
    index: null,
    path: '/usuario/editar',
    component: EditarUsuario,
    roles: ['ADM SOCIALLIS'],
  },
  {
    index: null,
    path: '/campanha',
    component: ListCampanha,
    roles: ['ADM SOCIALLIS'],
  },
  {
    index: null,
    path: '/campanha/:idCampanha',
    component: DetalhesCampanha,
    roles: ['ADM SOCIALLIS'],
  },
  {
    index: null,
    path: '/estrategia/:idEstrategia',
    component: ConfigEstrategia,
    roles: ['ADM SOCIALLIS'],
  },
  {
    index: 8,
    path: '/financeiro',
    component: Financeiro,
    roles: ['ADM SOCIALLIS'],
  },
  {
    path: '/financeiro/gerarRemessa',
    component: Financeiro,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    path: '/financeiro/importarRetorno',
    component: ImportarRetorno,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    index: null,
    path: '/remessa/debito/detalhes',
    component: DetalhesRemessa,
    roles: ['ADM SOCIALLIS'],
  },
  {
    path: '/financeiro/historicoRemessa',
    component: HistoricoRemessa,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    index: null,
    path: '/historico/remessa/detalhes',
    component: DetalhesHistoricoRemessa,
    roles: ['ADM SOCIALLIS'],
  },
  {
    path: '/estrategias/',
    component: ListEstrategias,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    path: '/estrategias/:estrategiaID',
    component: DetalhesEstrategia,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    path: '/dashboard',
    component: DashboardCadastros,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    path: '/cadastros/solicitacoes',
    component: ListSolicitacoes,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    path: '/cadastros/tipo/perfil',
    component: TipoPerfil,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    index: null,
    path: '/inserir/doacoes',
    component: InserirDoacoes,
    roles: ['ADM SOCIALLIS'],
  },
  {
    index: null,
    path: '/envios',
    component: ListEnvios,
    roles: ['ADM SOCIALLIS'],
  },
  {
    index: null,
    path: '/correspondencia',
    component: ListCorrespondenciaDevolvida,
    roles: ['ADM SOCIALLIS'],
  },
  {
    path: '/ligacoes',
    component: ListaLigacao,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    path: '/consultas',
    component: Consultas,
    roles: ['ADM SOCIALLIS'],
    index: 10,
  },
  {
    path: '/solicitacao/atendimento',
    component: SolicitacaoAtendimento,
    roles: ['ADM SOCIALLIS'],
    index: 9,
  },
  {
    path: '/solicitacao/atendimento/novo',
    component: SolicitacaoAtendimento,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    path: '/solicitacao/atendimento/cadastro',
    component: CreateHorarios,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    path: '/solicitacao/atendimento/lista',
    component: ListaAtendidos,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  /*{
    path: '/dashboard/novos',
    component: DashboardCadastros,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },
  {
    path: '/dashboard/comparativo',
    component: DashboardComparativo,
    roles: ['ADM SOCIALLIS'],
    index: null,
  },*/
];

export const routesAdminMenu: IRouteMenu[] = [
  {
    index: 1,
    path: '/home',
    label: 'Home',
    Icon: <FavoriteBorderOutlined />,
    roles: ['ADM SOCIALLIS'],
    menu: 'header',
  },
  {
    index: 2,
    label: 'Cadastros',
    path: '/cadastros',
    Icon: ContactPage,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
    subMenu: [
      {
        index: 2.1,
        path: '/cadastros/novo',
        label: 'Novo',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
      {
        index: 2.2,
        path: '/cadastros/consulta',
        label: 'Consulta',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
      {
        index: 2.3,
        path: '/cadastros/solicitacoes',
        label: 'Solicitações',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
      {
        index: 2.4,
        path: '/cadastros/tipo/perfil',
        label: 'Tipo de perfis',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
      {
        index: 2.5,
        path: '/cadastros/indicadores',
        label: 'Indicadores',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
      //{
       // index: 2.6,
        //path: '/cadastros/dadosGerais',
        //label: 'Dados Gerais',
        //roles: ['ADM SOCIALLIS'],
       // menu: 'lateral',
      //},
      {
        index: 2.7,
        path: '/cadastros/consulta/triagem',
        label: 'Consulta triagem',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
    ],
  },
  {
    index: 3,
    label: 'Inserção de doações',
    path: '/inserir/doacoes',
    Icon: LoyaltyOutlined,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
  },
  {
    index: 4,
    label: 'Campanha',
    path: '/campanha',
    Icon: BookMarkIcon,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
  },
  {
    index: 5,
    label: 'Envios',
    path: '/envios',
    Icon: SendOutlined,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
  },
  {
    index: 6,
    label: 'Correspondência Devolvida',
    path: '/correspondencia',
    Icon: Article,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
  },
  {
    index: 7,
    label: 'Ligações',
    path: '/ligacoes',
    Icon: PhoneInTalk,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
  },
  {
    index: 8,
    path: '/estrategias/',
    label: 'Estratégias',
    Icon: <StickyNote2 />,
    roles: ['ADM SOCIALLIS'],
    menu: 'header',
  },
  {
    index: 9,
    label: 'Solicitação atendimento',
    path: '/solicitacao/atendimento',
    Icon: mdiTextAccount,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
    subMenu: [

      {
        index: 9.1,
        path: '/solicitacao/atendimento/novo',
        label: 'Nova solicitação',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
      {
        index: 9.2,
        path: '/solicitacao/atendimento/cadastro',
        label: 'Horários',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
      {
        index: 9.3,
        path: '/solicitacao/atendimento/lista',
        label: 'Lista atendidos',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },

    ],
  },
  {
    index: 10,
    path: '/consultas',
    label: 'Consultas',
    Icon: mdiDatabaseSearch,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
  },
  {
    index: 11,
    label: 'Financeiro',
    path: '/financeiro',
    Icon: PaymentOutlined,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
    subMenu: [
      // {
      //   index: 8.1,
      //   path: '',
      //   label: 'Emitir Boleto',
      //   roles: ['ADM SOCIALLIS'],
      //   menu: 'lateral',
      // },
      // {
      //   index: 8.2,
      //   path: '',
      //   label: 'Histórico de boleto',
      //   roles: ['ADM SOCIALLIS'],
      //   menu: 'lateral',
      // },
      {
        index: 11.3,
        path: '/financeiro/gerarRemessa',
        label: 'Gerar Remessa',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
      // {
      //   index: 10.4,
      //   path: '',
      //   label: 'Histórico de remessa',
      //   roles: ['ADM SOCIALLIS'],
      //   menu: 'lateral',
      // },
      {
        index: 11.5,
        path: '/financeiro/importarRetorno',
        label: 'Importar retorno',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
      // {
      //   index: 8.6,
      //   path: '',
      //   label: 'Histórico de arquivos',
      //   roles: ['ADM SOCIALLIS'],
      //   menu: 'lateral',
      // },
      {
        index: 11.6,
        path: '/financeiro/historicoRemessa',
        label: 'Histórico remessa',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
    ],
  },
  {
    index: 12,
    label: 'Usuários',
    path: '/usuarios',
    Icon: AccountBoxIcon,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
  },

  /*{
    index: 12,
    label: 'Dashboard',
    Icon: BarChart,
    roles: ['ADM SOCIALLIS'],
    menu: 'lateral',
    subMenu: [
      {
        index: 12.1,
        path: '/dashboard/novos',
        label: 'Novos e Geral',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
      {
        index: 12.2,
        path: '/dashboard/comparativo',
        label: 'Comparativo',
        roles: ['ADM SOCIALLIS'],
        menu: 'lateral',
      },
    ],
  },*/
];
