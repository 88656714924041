import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import api from '@http/api';
import { useAuth } from '@hooks/AuthenticateContext';

import { Checkbox, Grid, IconButton, Card } from '@material-ui/core';
import { AddBox, ClearRounded, AddOutlined, Edit, VisibilityOff, TuneOutlined} from '@material-ui/icons';
import Button from '@components/Shared/Buttons/Button';
import { FormatDate } from '@utils/formats';
import { Form, Select as SelectAntd,  Input, Spin, Radio, Switch } from 'antd';
import {  addDays } from 'date-fns';

import Filtros from '@src/components/Shared/Filtros';
import TableCustomKeysNoPagination from '@src/components/Shared/Tables/TableCustomKeysNoPagination';

import Services, {
  ILista,
  ILista2,
} from './services';


const columnsLabel = {
  id: 'ID',
  nomeCompleto: 'Nome',
  estadoId: 'Estado',
  cidadeId: 'Cidade',
  tipoIntencaoMissa: 'Intenção',
  dataIntencaoInicio: 'Data início',
  dataIntencaoFim: 'Data fim',
  descricao: 'Descrição',
};


let dataSelect: any;
let conteudo: any;

const SolicitacaoIntencao: React.FC = () => {
  const [formRef] = Form.useForm();
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<ILista[]>([]);
  const [intencoes, setIntencoes] = useState<ILista[]>([]);
  const [tipoSolicitacaoIntencao, setTipoSolicitacaoIntencao] = useState<any>(37);

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingLista, setLoadingLista] = useState(true);

  const [filtros, setFiltros] = useState(false);

  var conteudoResposta = JSON.parse(JSON.stringify(intencoes));
  conteudo = data.length <= 0

  const loadAllIntencoes = async () => {
    setLoading(true);
    const services = new Services();
    const { error, response } = await services.GetListaAllIntencoes(tipoSolicitacaoIntencao);

    if (!error && response) {
      setIntencoes(response);
    }

    setLoading(false);
  };

  const loadAllIntencoesFiltros = async () => {
    setLoading(true);
    const services = new Services();
    const { error, response } = await services.GetListaAllIntencoesMissaFiltros(tipoSolicitacaoIntencao, dataSelect);

    if (!error && response) {
      setIntencoes(response);
    }

    setLoading(false);
  };

  async function loadListaAllIntencao() {
    setLoadingLista(true);
    await api
      .get( `api/IntencaoMissa/GetAllIntencaoMissa?idTipoSolicitacao=${tipoSolicitacaoIntencao}`)

      .then(response => {
        const items = response.data;
        const itemData: ILista[] = items.map((item: ILista) => ({
          id: item.id,
          nomeCompleto: item.nomeCompleto,
          estadoId: item.estado.sigla,
          cidadeId: item.cidade.descricao,
          tipoIntencaoMissa: item.tipoIntencaoMissa.descricao,
          dataIntencaoInicio: FormatDate(item.dataIntencaoInicio),
          dataIntencaoFim: FormatDate(item.dataIntencaoFim),
          descricao: item.descricao,
          //dataCadastro: FormatDate(item.dataCadastro),
        }));

        setData(itemData);
        setLoadingLista(true);
      })
      .catch(error => {
        alert(error);
      });
  }

  async function loadListaAllIntencaoFiltros() {
    setLoading(true);
    await api
      .get( `api/IntencaoMissa/GetAllIntencaoMissa?idTipoSolicitacao=${tipoSolicitacaoIntencao}&dataIntencaoInicio=${dataSelect}`)

      .then(response => {
        const items = response.data;
        const itemData: ILista[] = items.map((item: ILista) => ({
          id: item.id,
          nomeCompleto: item.nomeCompleto,
          estadoId: item.estado.sigla,
          cidadeId: item.cidade.descricao,
          tipoIntencaoMissa: item.tipoIntencaoMissa.descricao,
          dataIntencaoInicio: FormatDate(item.dataIntencaoInicio),
          dataIntencaoFim: FormatDate(item.dataIntencaoFim),
          descricao: item.descricao,
          //dataCadastro: FormatDate(item.dataCadastro),
        }));

        setData(itemData);
        setLoading(false);
      })
      .catch(error => {
        alert(error);
      });
  }


  const downloadFile = async () => {
    try {

      const createSolicitacao: ILista2 = {
        intencoes_missa:
        conteudoResposta
      };



      const response = await fetch('https://afipe-trismeg.azurewebsites.net/api/IntencaoMissa/GerarLista', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

          'Authorization': `Bearer ${user.token}`, // Exemplo de token
        },
         body: JSON.stringify(createSolicitacao)
      });


      console.log('meud deddus', response)

     if (response.ok) {
        // Obter o nome do arquivo a partir do cabeçalho "Content-Disposition"
        const disposition = response.headers.get('Content-Disposition');
        const filename = disposition && disposition.split('filename=')[1]?.replace(/"/g, '');

        // Criar um Blob com os dados binários recebidos
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);


        const link = document.createElement('a');
        link.href = url;
        link.download = filename || 'arquivo';
        //document.body.appendChild(link);
        link.click();

        // Limpar o link criado
        //document.body.removeChild(link);
        window.URL.revokeObjectURL(url);


        console.log("bob", blob)
        console.log("LINk", link)
        console.log("URL", url)
        console.log("response", response)
        //console.log("URL", body)
      } else {
        console.error('Erro ao gerar o arquivo: ', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao realizar o download: ', error);
    }
  };


  async function pegarData(data_intencao_inicio: Date){

    console.log('DATAAA', data_intencao_inicio)

    dataSelect = new Date (data_intencao_inicio).toISOString();

    loadListaAllIntencaoFiltros();
    loadAllIntencoesFiltros()
  };

  const handleOpen = () => {
    setFiltros(!filtros);
  };

  const clearData = () => {
    formRef.setFieldsValue({
      data_intencao_inicio: '',
    });
  };

  useEffect(() => {
    loadListaAllIntencao();
    loadAllIntencoes();

    window.document.title = 'CRM - Lista Intenções';
  }, []);

  return (
    <Grid container>
      <Grid
        container
        item
        sm={12}
        //justify="space-around"
        xs={12}
        lg={12}
        style={{ marginTop: 20 }}
      >
        <Grid item lg={12} sm={12} xs={12}>

          {/* Botões  */}
          <Grid container item lg={10} sm={10} xs={10} style={{ paddingTop: 10 }}>
            <Grid
              item
              md={8}
              lg={8}
              xs={6}
              style={{
                paddingRight: 5,
                paddingLeft: 5,
                alignSelf: 'flex-end',
                paddingTop: 10,
              }}
            >
              <Button
                color="secondary"
                fullWidth
                style={{
                  height: 46,
                  width: 200
                }}
                onClick={handleOpen}
              >
                <TuneOutlined style={{ marginRight: 10 }} /> Filtros
              </Button>
            </Grid>

            <Grid
              item
              md={3}
              lg={3}
              xs={4}
              style={{
                paddingRight: 5,
                paddingLeft: 20,
                alignSelf: 'end',
                paddingTop: 10,
              }}
            >
              <Button
                color="primary"
                style={{
                  height: 46,
                  width: 200
                }}
                onClick={downloadFile}
                disabled={conteudo === true}
              >
                Gerar PDF
              </Button>
            </Grid>
          </Grid>

          {/* Filtros  */}
          <Filtros abrir={filtros} openMenu={handleOpen}>
            <Grid
              item
              md={12}
              lg={12}
              xs={12}
              style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
            >
              <Form className="filtros " layout={'vertical'} form={formRef}
               initialValues={{

                data_intencao_inicio: '',
              }}>

                <Grid
                  item
                  md={12}
                  lg={12}
                  xs={12}
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    alignSelf: 'flex-end',
                    paddingTop: 10,
                  }}
                >
                  <Form.Item
                    name="data_intencao_inicio"
                  >
                    <p style={{color: '#fff'}}>Data início intenção:</p>
                    <Input type="date" onChange={(e: any) => {pegarData(e.target.value);}}/>
                  </Form.Item>
                </Grid>

                <Grid
                  item
                  md={12}
                  lg={12}
                  xs={12}
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    alignSelf: 'flex-end',
                    paddingTop: 10,
                  }}
                >
                  <Button
                    color="primary"
                    fullWidth
                    style={{
                      height: 46,
                    }}
                    onClick={(e: any) => {loadListaAllIntencao(); clearData() }}

                    //onClick={loadListaAllIntencao}
                  >
                    Limpar filtros
                  </Button>
                </Grid>
              </Form>
            </Grid>
          </Filtros>

          {/* Tabela  */}

          <Grid container item lg={9} sm={9} xs={12} style={{ padding: 0 }}>
            <Grid
              item
              lg={12}
              xs={12}
              style={{ paddingRight: 2, paddingLeft: 2, paddingTop: 5 }}
            >

              <TableCustomKeysNoPagination
                loading={loading}
                columns={[columnsLabel]}
                FieldsBold={['tipoIntencaoMissa']}
                data={data}
                keys={[
                  'id',
                  'nomeCompleto',
                  'estadoId',
                  'cidadeId',
                  'tipoIntencaoMissa',
                  'dataIntencaoInicio',
                  'dataIntencaoFim',
                  'descricao',
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SolicitacaoIntencao;
